import MarketplaceSearchContainer from '~/src/components/marketplace/ui/search/MarketplaceSearchContainer'
import useGetSearchTemplate from '~/src/pages/search/styles/useGetSearchTemplate'
import ProductSearchContainer from '~/src/components/products/ProductSearchContainer'
import DocumentTitleInjector from '~/src/components/root/DocumentTitleInjector'
import { useEoValue } from '~/src/hooks/useEoState'
import { IS_MARKETPLACE_SELECTOR } from '~/src/stores/brand'
import RoutesEnum from '~/src/router/enums/routes.enum'
import SlideFromRight from '~/src/components/mui-wrappers/animations/motion/SlideFromRight'

const SearchResult = () => {
  const pageName: keyof typeof RoutesEnum = 'SEARCH'
  const isMarketplace = useEoValue(IS_MARKETPLACE_SELECTOR)

  useGetSearchTemplate()

  return (
    <SlideFromRight
      pathname={pageName}
      transition={{
        duration: .5
      }}
    >
      <DocumentTitleInjector pageName={pageName}/>
      { isMarketplace ? <MarketplaceSearchContainer /> : <ProductSearchContainer /> }
    </SlideFromRight>
  )
}

export default SearchResult
