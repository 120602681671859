import { SxProps } from '@mui/material'
import StylesSearchServiceInterface from '~/src/pages/search/styles/StylesSearchServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'

export default class StylesSearchServiceStyleThree extends TemplateServiceStyleBase implements StylesSearchServiceInterface {
  public getPageSx(): SxProps {
    return {}
  }

  public getTitleSx(): SxProps {
    return {}
  }
}
