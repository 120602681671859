import { Box } from '@mui/material'
import LocationSearchAutocomplete from '~/src/components/marketplace/ui/search/LocationSearchAutocomplete'
import SearchResults from '~/src/components/marketplace/ui/search/SearchResults'
import PageHeader from '~/src/components/marketplace/utils/PageHeader'
import { useTranslations } from '~/src/hooks/useTranslations'

const MarketplaceSearchContainer = () => {
  const { t } = useTranslations()

  return (
    <Box sx={{
      display: 'grid',
      gridTemplateAreas: '"page-header" "page-search" "search-results"',
      gridTemplateColumns: '1fr',
      gridTemplateRows: 'repeat(2, max-content) auto',
      px: 2
    }}>
      <PageHeader
        title={t('search.placeholder')}
        sx={{
          gridArea: 'page-header',
          px: 0
        }}/>
      <LocationSearchAutocomplete
        PaperProps={{
          sx: {
            gridArea: 'page-search'
          }
        }}/>
      <Box sx={{
        gridArea: 'search-results',
        py: 2
      }}>
        <SearchResults
          sx={{
            minHeight: 'unset',
            maxHeight: 'unset'
          }}
        />
      </Box>
    </Box>
  )
}

export default MarketplaceSearchContainer
