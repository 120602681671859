import { SxProps } from '@mui/material'
import StylesSearchServiceInterface from '~/src/pages/search/styles/StylesSearchServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'

export default class StylesSearchServiceStyleOne extends TemplateServiceStyleBase implements StylesSearchServiceInterface {
  public getPageSx(): SxProps {
    return {
      pt: 4,
      pl: 4,
      [this.muiTheme.breakpoints.down('md')]: {
        p: 0,
        pt: 2
      }
    }
  }

  public getTitleSx(): SxProps {
    return {
      fontWeight: 600,
      pb: 4,
      color: 'background.contrastText',
      [this.muiTheme.breakpoints.down('md')]: {
        p: 2,
        pt: 0
      }
    }
  }
}
