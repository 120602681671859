import getSearchTemplateService from '~/src/pages/search/styles/GetSearchTemplateService'
import StylesSearchServiceInterface from '~/src/pages/search/styles/StylesSearchServiceInterface'
import useGetPageStyle from '~/src/hooks/layout/useGetPageStyle'
import { useLayout } from '~/src/hooks/layout/useLayout'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'

const useGetSearchTemplate = (): StylesSearchServiceInterface => {
  const muiTheme = useCustomMuiTheme()
  const pageStyle = useGetPageStyle('search')

  useLayout('search')

  return getSearchTemplateService(pageStyle, muiTheme)
}

export default useGetSearchTemplate
