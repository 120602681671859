import useGetSearchTemplate from '~/src/pages/search/styles/useGetSearchTemplate'
import { useEoValue } from '~/src/hooks/useEoState'
import { PRODUCT_SEARCH_SELECTOR } from '~/src/stores/product'
import { useTranslations } from '~/src/hooks/useTranslations'
import ProductSearchNoMatch from '~/src/components/products/ProductSearchNoMatch'
import { Box } from '@mui/material'
import Title from '~/src/components/mui-wrappers/Title'
import ProductList from '~/src/components/products/product-list'
import { useParams } from 'react-router-dom'

const ProductSearchContainer = () => {
  const styles = useGetSearchTemplate()
  const params = useParams()
  const productSearchResult = useEoValue(PRODUCT_SEARCH_SELECTOR(params.search || ''))
  const { t } = useTranslations()

  if (!params.search || productSearchResult.length === 0) {
    return <ProductSearchNoMatch />
  }

  return (
    <Box sx={styles?.getPageSx()}>
      <Title
        variant='h5'
        sx={styles?.getTitleSx()}
      >
        {t('search.results')}: {params.search}
      </Title>
      <ProductList productsId={productSearchResult} />
    </Box>
  )
}

export default ProductSearchContainer
