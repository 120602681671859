import DangerousIcon from '@mui/icons-material/Dangerous'
import { Box } from '@mui/material'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { useTranslations } from '~/src/hooks/useTranslations'

const ProductSearchNoMatch = () => {
  const { t } = useTranslations()
  const muiTheme = useCustomMuiTheme()

  return (
    <Box
      sx={{
        backgroundColor: 'container.main',
        color: 'container.contrastText',
        width: '40%',
        m: 'auto',
        mt: 3,
        p: 4,
        textAlign: 'center',
        boxShadow: muiTheme.elevation,
        [muiTheme.breakpoints.only('xs')]: {
          width: '90%'
        }
      }}
    >
      <DangerousIcon color='error' sx={{ fontSize: '6rem' }} />
      <br />
      {t('search.no_match')}
    </Box>
  )
}

export default ProductSearchNoMatch
