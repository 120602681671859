import StylesSearchServiceInterface from '~/src/pages/search/styles/StylesSearchServiceInterface'
import StylesSearchServiceStyleOne from '~/src/pages/search/styles/StylesSearchServiceStyleOne'
import StylesSearchServiceStyleTwo from '~/src/pages/search/styles/StylesSearchServiceStyleTwo'
import { CustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { TemplateEnum } from '@eo-storefronts/eo-core'
import StylesSearchServiceStyleThree from '~/src/pages/search/styles/StylesSearchServiceStyleThree'

const getSearchTemplateService = (style: TemplateEnum | null, muiTheme: CustomMuiTheme): StylesSearchServiceInterface => {
  switch (style) {
    case TemplateEnum.STYLE_THREE:
      return new StylesSearchServiceStyleThree(muiTheme)
    case TemplateEnum.STYLE_TWO:
      return new StylesSearchServiceStyleTwo(muiTheme)
    default:
      return new StylesSearchServiceStyleOne(muiTheme)
  }
}

export default getSearchTemplateService
